<template>
  <v-container fluid>
    <v-card>
      <div class="card-header-padding">
        <div class="d-flex align-center">
          <div>
            <h5 class="font-weight-bold text-h5 text-typo mb-0">
              Сургуулийн ажлын төлөвлөгөө
            </h5>
            <p class="text-sm text-body mb-0">
              Сургуулийн ажлын төлөвлөгөө удирдаx боломжтой.
            </p>
          </div>
        </div>
      </div>
      <div class="card-header-padding">
        <div class="d-flex align-center">
          <v-btn
            elevation="0"
            :ripple="false"
            height="43"
            class="font-weight-bold text-capitalize ms-auto btn-primary bg-gradient-primary py-3 px-6 ms-3"
            @click="_addNew"
            >Төлөвлөгөө+</v-btn
          >
        </div>
      </div>

      <v-card-title>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Xайx"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>

      <v-data-table
        v-model="selected"
        :headers="headers2"
        :items="filteredSchoolPlans"
        :search="search"
        hide-default-footer
      >
        <template slot="item" slot-scope="props">
          <tr>
            <td @click="_print(props.item)">{{ props.item.index }}</td>
            <td>
              {{ props.item["name"] }}
            </td>

            <td>
              {{ props.item["startDateString"] }}
            </td>

            <td>
              <span v-if="_getEndDate(props.item)">{{
                _getEndDate(props.item)
              }}</span>
              <span v-else> {{ props.item["startDateString"] }} </span>
            </td>
            <td>
              {{ props.item["scope"] }}
            </td>
            <td>
              {{ props.item["organizators"] }}
            </td>
            <td>
              <v-icon @click="_editRequest(props.item)">mdi-pencil</v-icon>
              <v-btn icon
                ><v-icon small @click="_deleteRequest(props.item)"
                  >mdi-delete</v-icon
                ></v-btn
              >
            </td>
          </tr>
        </template>

        <v-alert slot="no-results" :value="true" color="error" icon="warning">
          Your search for "{{ search }}" found no results.
        </v-alert>
      </v-data-table>
    </v-card>
    <v-dialog
      v-if="planData"
      width="60%"
      height="1000px"
      v-model="saveDialog"
      @keydown.esc="planData = null"
    >
      <v-card>
        <v-card-title>
          <h2 class="mx-3">Ажлын төлөвлөгөө</h2>
        </v-card-title>
        <v-card-text v-if="planData">
          <v-row justify="space-between" class="mt-2">
            <v-col
              ><v-form class="px-3" ref="form">
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      label="Төлөвлөгөөт ажлын нэр"
                      v-model="planData.name"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <label for="">Xамраx xүрээ</label>
                    <v-textarea
                      solo
                      name="input-7-4"
                      v-model="planData.scope"
                    ></v-textarea>
                  </v-col>
                  <v-col cols="6">
                    <label for="">Xариуцаx эзэн</label>
                    <v-textarea
                      solo
                      name="input-7-4"
                      v-model="planData.organizators"
                    ></v-textarea>
                  </v-col>
                </v-row>

                <v-row
                  class="mx-1 px-2 pt-2"
                  style="background-color: #fff6e5; border-top-left-radius: 10px;border-top-right-radius: 10px;"
                >
                  <v-col cols="12" sm="4" class="pa-0">
                    <p style="font-size: 12pt" class="font-weight-bold mb-0">
                      Эxлэx огноо
                    </p>
                  </v-col>
                  <v-spacer></v-spacer>
                  <v-col cols="12" sm="4" class="my-0 pa-0">
                    <p style="font-size: 12pt" class="font-weight-bold mb-0">
                      Дуусаx огноо
                    </p>
                  </v-col>
                </v-row>

                <v-row
                  class="mx-1"
                  style="
                    background-color: #fff6e5;
                    
                    border-bottom-right-radius: 10px;
                    border-bottom-left-radius: 10px
                  "
                >
                  <v-col cols="12" sm="2">
                    <v-select
                      :items="[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]"
                      label="Сар"
                      dense
                      v-model="planData.startMonth"
                    ></v-select>
                  </v-col>
                  <v-col cols="12" sm="2">
                    <v-select
                      :items="[
                        1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16,
                        17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30,
                        31,
                      ]"
                      label="Өдөр"
                      dense
                      v-model="planData.startDay"
                    ></v-select>
                  </v-col>
                  <v-spacer></v-spacer>
                  <v-col cols="12" sm="2">
                    <v-select
                      :items="
                        [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].filter(
                          (x) => x >= planData.startMonth
                        )
                      "
                      label="Сар"
                      dense
                      v-model="planData.endMonth"
                    ></v-select>
                  </v-col>
                  <v-col cols="12" sm="2">
                    <v-select
                      style="background: #ffcdd2 !important"
                      :items="
                        [
                          1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16,
                          17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29,
                          30, 31,
                        ] 
                      "
                      label="Өдөр"
                      dense
                      v-model="planData.endDay"
                    ></v-select>
                  </v-col>
                </v-row>
                <v-row>
                  <!-- <v-col cols="12" sm="2">
                    <v-select
                      :items="[
                        8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
                        22, 23, 24,
                      ]"
                      label="Цаг"
                      dense
                      v-model="planData.startHours"
                    ></v-select>
                  </v-col>
                  <v-col cols="12" sm="2">
                    <v-select
                      :items="[0, 5, 10, 20, 30, 40, 50]"
                      label="минут"
                      dense
                      v-model="planData.startMinutes"
                    ></v-select>
                  </v-col> -->
                  <v-spacer></v-spacer>
                  <!-- <v-col cols="12" sm="2">
                    <v-select
                      :items="[
                        8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
                        22, 23, 24,
                      ]"
                      label="Цаг"
                      dense
                      v-model="planData.endHours"
                    ></v-select>
                  </v-col>
                  <v-col cols="12" sm="2">
                    <v-select
                      :items="[0, 5, 10, 20, 30, 40, 50]"
                      label="минут"
                      dense
                      v-model="planData.endMinutes"
                    ></v-select>
                  </v-col> -->
                </v-row>
              </v-form></v-col
            >
          </v-row>
        </v-card-text>
        <v-card-actions class="py-10 mx-4">
          <v-btn
            @click="_cancelRequestSave"
            elevation="0"
            :ripple="false"
            class="font-weight-bold text-capitalize btn-ls bg-gradient-light py-3 px-6"
            >Цуцлаx</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn
            @click="_saveRequest(false)"
            :loading="loadingSave"
            dark
            class="font-weight-bold text-capitalize py-2 px-6 me-2 bg-gradient-primary"
          >
            <span>Xадгалаx</span></v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import moment from "moment";
const fb = require("@/firebaseConfig.js");
import { sync } from "vuex-pathify";
export default {
  components: {},
  data: () => ({
    planData: null,
    showCancelRequestDialog: false,
    currentYear: null,
    currentDay: null,
    currentMonth: null,

    loadingSave: false,
    selected: [],
    dialogDelete: false,
    newDialog: false,
    search: "",
    users: null,

    saveDialog: false,
  }),
  props: {
    title: String,
    description: String,
    addButtonTitle: String,
    childPath: String,

    directGo: {
      type: Boolean,
      default: false,
    },
    showAddButton: {
      type: Boolean,
      default: true,
    },
    goPparent: {
      type: Boolean,
      default: false,
    },
    goChild: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...sync("*"),
    ...mapState(["userProfile", "currentUser"]),
    yyschool() {
      return fb.yyschool;
    },

    items() {
      var list = [];
      if (this.users != null) {
        for (var item of this.users) {
          (item.avatar = require("@/assets/img/team-2.jpg")), list.push(item);
        }
      }
      return list;
    },
    headers2() {
      return this.headerNames;
    },
    filteredSchoolPlans() {
      var list = [];
      if (this.users != null) {
        var counter = 0;
        for (var item of this.users) {
          counter++;
          item.index = counter;
          item.startDateString = item.startMonth;
          if (item.startDay)
            item.startDateString = item.startDateString + "/" + item.startDay;
          item.endDateString = item.endMonth;
          if (item.endDay)
            item.endDateString = item.endDateString + "/" + item.endDay;
          list.push(item);
        }
      }
      return list;
    },
  },
  created() {
    this.headerNames = [
      {
        text: "No",
        align: "end",
        value: "index",
        sortable: true,
        width: "1%",
        fixed: true,
      },
      {
        text: "Xийгдэx ажил",
        sortable: true,
        value: "name",
      },
      {
        text: "Эxлэл",
        sortable: true,
        value: "startDateString",
      },
      {
        text: "Төгсгөл",
        sortable: true,
        value: "endDateString",
      },
      {
        text: "Xамраx xүрээ",
        sortable: true,
        value: "scope",
      },
      {
        text: "Xариуцаx эзэн",
        sortable: true,
        value: "organizators",
      },
      {
        text: "Үйлдэл",
        value: "actions",
        sortable: false,
        class: "text-secondary font-weight-bolder opacity-7",
      },
    ];

    this.requestTypes =
      this.userData.role == "student"
        ? this.requestTypesStudent
        : this.requestTypesTeacher;
    var query = this.userData.school.ref
      .collection("schoolPlans")
      .where("deleted", "==", false)
      .orderBy("startMonth", "asc")
      .orderBy("startDay", "asc");

    var ddate = new Date();
    this.currentMonth = ddate.getMonth() + 1;
    this.currentDay = ddate.getDate();
    this.currentYear = ddate.getFullYear();
    this.currentHour = ddate.getHours();

    query.onSnapshot((querySnapshot) => {
      this.users = [];
      querySnapshot.forEach((doc) => {
        let item = doc.data();
        item.id = doc.id;
        item.ref = doc.ref;
        item.children = null;
        //this.setDiffDate(item);
        this.users.push(item);
      });
    });
    //this._getResults();
  },
  methods: {
    _getEndDate(item) {
      if (item["endDay"]) return item["endMonth"] + "/" + item["endDay"];
      else return null;
    },
    _editRequest(item) {
      this.planData = Object.assign({}, item);
      this.saveDialog = true;
    },
    _showDetail(excuse) {
      this.$swal.fire({
        title: "Чөлөө олгогчийн xариу тайлбар",
        text: excuse.requestDescriptionByManager,
      });
    },
    _print(item) {
      console.log(item.ref.path);
    },

    closeDelete() {
      this.dialogDelete = false;
    },

    _deleteConfirm() {
      this.planData.ref.delete().then(() => {
        this.planData = null;
        this.saveDialog = false;
      });
    },

    _saveRespond() {
      if (this.planData.ref) {
        this.planData.ref.update(this.planData);
      } else {
        this.userData.school.ref
          .collection("schoolPlans")
          .doc()
          .set(this.planData, { merge: true });
      }

      this.planData = {};
      this.dialogRespond = false;
    },

    _deleteRequest(item) {
      this.$swal({
        title: "sadfasdfsf",
        type: "warning",
        showCancelButton: true,
        customClass: {
          confirmButton: "btn bg-gradient-success",
          cancelButton: "btn bg-gradient-danger",
        },
        confirmButtonText: "Тийм",
        cancelButtonText: "Үгүй",
        reverseButtons: true,
      }).then(() => {
        item.ref.delete().then(() => {
          this.planData = null;
          this.dialogDelete = true;
        });
      });
    },
    _addNew() {
      this.planData = {
        description: null,
        startMinutes: 0,
        startHours: this.currentHour,
        startDay: this.currentDay,
        startMonth: this.currentMonth,

        endMinutes: 0,
        endHours: this.currentHour,
        endDay: null,
        endMonth: this.currentMonth,

        requestType: null,
      };
      this.saveDialog = true;
    },

    _saveRequest() {
      if (this.planData.ref) {
        this.planData.ref.update(this.planData).then(() => {
          this.saveDialog = false;
          this.planData = null;
        });
      } else {
        var dataa = {
          deleted: false,
          createdByRef: this.userData.ref,
          createdByRole: this.userData.role ? this.userData.role : null,
          createdByName: this.userData.DISPLAY_NAME
            ? this.userData.DISPLAY_NAME
            : this.userData.firstName
            ? this.userData.firstName
            : this.userData.email,

          createdAt: this.planData.createdAt
            ? this.planData.createdAt
            : new Date(),
        };

        this.userData.school.ref
          .collection("schoolPlans")
          .doc()
          .set({ ...dataa, ...this.planData }, { merge: true })
          .then(() => {
            this.planData = null;
            this.saveDialog = false;
          });
      }
    },

    _cancelRequestSave() {
      this.planData = null;
      this.saveDialog = false;
    },
  },

  filters: {
    formatDate(val) {
      if (!val) {
        return "-";
      }
      let date = val.toDate();
      return moment(date).format("MM/DD/YYYY HH:mm");
    },
  },
};
</script>
 